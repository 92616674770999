import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Error = () => {
  return (
    <>
      <Helmet>
        <title>Sacred Art Tattoo Kailua | 404</title>
      </Helmet>
      <div className="container mt-60 mb-60">
        <h1>Page not found</h1>
        <Button className="mt-30" variant="dark">
          <Link to="/">Return home</Link>
        </Button>
      </div>
    </>
  );
};

export default Error;
